import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";


const ThemeRoutes = () => {

let userStore = localStorage.getItem('kerjak-user')

if(userStore !== null && userStore !== ''){ 
    userStore = JSON.parse(userStore)
}else{
  userStore = null
}
/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Home = lazy(() => import("../views/Home.js"));
const Lowongan = lazy(() => import("../views/Lowongan.js"));
const Lokasi = lazy(() => import("../views/Lokasi.js"));
const Keahlian = lazy(() => import("../views/Keahlian.js"));
const Perusahaan = lazy(() => import("../views/Perusahaan.js"));
const LowonganDetail = lazy(() => import("../views/LowonganDetail.js"));
const Pendidikan = lazy(() => import("../views/Pendidikan.js"));
const Terms = lazy(() => import("../views/Terms.js"));
const Profile = lazy(() => import("../views/Profile.js"));
const Logout = lazy(() => import("../views/Logout.js"));
/*****Routes******/
const {PUBLIC_URL} = process.env
return (
  <BrowserRouter basename={PUBLIC_URL}>
  <Routes>
    <Route element={<FullLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/termsandconditions.html" element={<Terms />} />
      <Route path="/:jobtitle/:jobtitle/:jobid/:jobseo/:jobseo.html" element={<LowonganDetail />} />
      <Route path="/:jobtitle/:jobid/:jobseo/:jobseo.html" element={<LowonganDetail />} />
      <Route path="/:jobtitle/:jobid/:jobseo.html" element={<LowonganDetail />} />
      <Route path="/lowongan/:keyword/page/:page" element={<Lowongan />} />
      <Route path="/lowongan/:keyword" element={<Lowongan />} />
      <Route path="/lowongan" element={<Lowongan />} />
      <Route path="/lokasi-kerja/:keyword/page/:page" element={<Lokasi />} />
      <Route path="/lokasi-kerja/:keyword" element={<Lokasi />} />
      <Route path="/lokasi-kerja" element={<Lowongan />} />
      <Route path="/lulusan/:keyword/page/:page" element={<Pendidikan />} />
      <Route path="/lulusan/:keyword" element={<Pendidikan />} />
      <Route path="/lulusan" element={<Lowongan />} />
      <Route path="/keahlian/:keyword/page/:page" element={<Keahlian />} />
      <Route path="/keahlian/:keyword" element={<Keahlian />} />
      <Route path="/keahlian" element={<Lowongan />} />
      <Route path="/perusahaan/:keyword/page/:page" element={<Perusahaan />} />
      <Route path="/perusahaan/:keyword" element={<Perusahaan />} />
      <Route path="/perusahaan" element={<Lowongan />} />
      <Route path="/:keyword/page/:page" element={<Lowongan />} />
      <Route path="/:keyword" element={<Lowongan />} />

      { userStore !== null && userStore.emailVerified !== false ?

        <>
        <Route path="/logout" element={<Logout />} />
        <Route path="/profile" element={<Profile />} />
        </>
      :''}
    </Route>
  </Routes>
  </BrowserRouter>
)
};

export default  ThemeRoutes;
